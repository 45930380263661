import React from "react";
import Header from "../../components/global/header/header";
import Hero from "../../components/heros/alternate";
import MainContent from "../../components/products/main-content";
import Footer from "../../components/global/footer/footer";
import PageTransition from "gatsby-plugin-page-transitions";
import { withIntl } from "../../i18n";
import { FormattedMessage } from "react-intl";
import { StickyContainer, Sticky } from 'react-sticky';
import cx from 'classnames';

// Images
import serviceDeskImage1 from "../../images/service-desk-1.jpg";
import dataCenterImage1 from "../../images/data-center.jpg";

import customSolution from "../../images/business-solutions/custom-made-solutions.jpg";

const Trading = [
	{
		heading: "experience",
		content: "outsource_your_trading_para"
	},
	{
		heading: "customisation",
		content: "a_customisable_approach_para"
	},
	{
		heading: "support",
		content: "we_provide_24_7_para"
	}
];

const RiskManagement = [
	{
		heading: "visibility",
		content: "make_effective_and_informed_decisions_para"
	},
	{
		heading: "real_time_data",
		content: "real_time_risk_management_para"
	},
	{
		heading: "complete_overview",
		content: "comprehensive_displays_para"
	}
];




class managedApplications extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			contactOpen: false
		};
    }
    
    isSmallerThan = (pix) => {
        if (typeof document !== 'undefined') {
            return document.documentElement.clientWidth < pix;
        }
        return false;
	}
	
	componentDidMount() {
        if(document.body.classList.contains('noscroll')) {
            document.body.classList.remove('noscroll');
        }
    }


	services = (sub, title, points, width) => {
		const clazz = `small-12 large-pull-2 large-${width} columns`;

		return (
            <StickyContainer>
                <section className="solser bg-white pad-top">
                    <div className="row expanded">
                        {/* Heading */}
                        <div className="small-12 large-push-2 large-3 columns print-ma__title">

                            {!this.isSmallerThan(760) ? (
                                <Sticky topOffset={0} bottomOffset={0}>
                                {({ style, isSticky }) => (
                                    <div className={cx("solser-heading mpad", { [ "sticky" ]: isSticky})} style={style}>
                                        <em><FormattedMessage id={sub} /></em>
                                        <h2 className="dark heading"><FormattedMessage id={title} /></h2>
                                    </div>
                                )}
                                </Sticky>
                            ) : (
                                <div className="solser-heading mpad">
                                    <em><FormattedMessage id={sub} /></em>
                                    <h2 className="dark heading"><FormattedMessage id={title} /></h2>
                                </div>
                            )}
                        </div>

                        {/* Accordians */}
                        <div className={clazz}>
                            {points.map((point, index) => {
                                return (
                                    <div className="pad-bot-small mpad print-ma__item" key={index}>
                                        <div className="heading">
                                            <FormattedMessage id={point.heading} tagName="h5" />
                                        </div>
                                        <p className="dark">
                                            <FormattedMessage id={point.content} />
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            </ StickyContainer>
		);
	};

	toggleContact = () => {
		this.setState({
			contactOpen: !this.state.contactOpen
		});
		document.body.classList.toggle("noscroll");
	};

	render() {

		return (<></>);
		
		return (
			<PageTransition
				defaultStyle={{
					transition: "all 1100ms cubic-bezier(0.8, 0, 0.2, 1)",
					position: "relative",
					width: "100%",
					opacity: 0,
					maxWidth: "2000px"
				}}
				transitionStyles={{
					entering: {
						opacity: 1
					},
					entered: {
						opacity: 1
					},
					exiting: {
						opacity: 0
					}
				}}
				transitionTime={1250}
			>
				<Header
					headerStyle="std"
					contactOpen={this.state.contactOpen}
					action={() => this.toggleContact()}
					{...this.props}
				/>

				<Hero
					featImage={customSolution}
					pageTitleSub="custom_made"
					pageTitleMain="solutions"
				/>

				<MainContent
					featImage={[serviceDeskImage1]}
					subTitle="technical"
					mainTitle="support_desk"
                    bodyCopy1="proactive_and_continuous_support_para"
                    noPadding={true}
				/>

				{this.services("risk", "management", RiskManagement, 4)}

				<MainContent
					featImage={[dataCenterImage1]}
					subTitle="servers_and"
					mainTitle="hosting"
				/>

				<Footer />
			</PageTransition>
		);
	}
}

export default withIntl(managedApplications);




// ./src/pages/solutions/managed-applications.jsx